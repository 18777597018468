import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../services/general.service';
import { SingletonService } from '../../services/singleton.service';

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss'],
})
export class AddressBookComponent implements OnInit {
  officialTokenAddresses = [
    {
      name: 'KKUB (Wrapped KUB)',
      symbol: 'KKUB',
      decimals: 18,
      address: '0x67eBD850304c70d983B2d1b93ea79c7CD6c3F6b5',
      image: 'https://app.diamon.finance/images/coins/kub.png',
    },
    {
      name: 'KUSDT (Bitkub-Peg USDT)',
      symbol: 'KUSDT',
      decimals: 18,
      address: '0x7d984C24d2499D840eB3b7016077164e15E5faA6',
      image: 'https://exchange.diamon.finance/images/coins/0x7d984C24d2499D840eB3b7016077164e15E5faA6.png',
    },
  ];

  tokenAddresses = [
    {
      name: 'DEEMEESOOK COIN',
      symbol: 'DMS',
      decimals: 18,
      address: '0xE660e9dcFF303B44D2FA0dF07171A876e8D9c7d9',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0xE660e9dcFF303B44D2FA0dF07171A876e8D9c7d9.png?raw=true',
    },
    {
      name: 'BITFARM COIN',
      symbol: 'BFC',
      decimals: 18,
      address: '0x07469227Ae76FD149383b595705Af9a390fF055B',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0x07469227ae76fd149383b595705af9a390ff055b.png?raw=true',
    },
    {
      name: 'KHAYA COIN',
      symbol: 'KHY',
      decimals: 18,
      address: '0x617a7867fF1b4b9DD5b1842102EF2a717A4817C0',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0x617a7867ff1b4b9dd5b1842102ef2a717a4817c0.png?raw=true',
    },
    {
      name: 'Jak Coin',
      symbol: 'JAK',
      decimals: 18,
      address: '0xaBc8ca072e3b237d77663BD0D677AeB9CEe50BDD',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0xabc8ca072e3b237d77663bd0d677aeb9cee50bdd.png?raw=true',
    },
    {
      name: 'Capsule',
      symbol: 'CAPSULE',
      decimals: 18,
      address: '0xB973De1FAbdd269eA2E6710151516d40c82585ef',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0xb973de1fabdd269ea2e6710151516d40c82585ef.png?raw=true',
    },
    {
      name: 'Frystal',
      symbol: 'FRYSTAL',
      decimals: 18,
      address: '0x305c158a9B3c7E218c18E249d83f21fB17891B95',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0x305c158a9b3c7e218c18e249d83f21fb17891b95.png?raw=true',
    },
    {
      name: 'Dark Matter',
      symbol: 'DM',
      decimals: 18,
      address: '0x8349aD75EC0930e1652bEE5ee7680DE9Ff543FF8',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0x8349ad75ec0930e1652bee5ee7680de9ff543ff8.png?raw=true',
    },
    {
      name: 'Dark Frystal',
      symbol: 'DF',
      decimals: 18,
      address: '0xc2886A9f9123Ed3E7013E6039B3A2608151E2637',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0xc2886a9f9123ed3e7013e6039b3a2608151e2637.png?raw=true',
    },
    {
      name: 'Pet Token',
      symbol: 'PT',
      decimals: 18,
      address: '0xe5c229095B375e792e76B6efE5d4670d4118cF1F',
      image:
        'https://github.com/bl0ck0x88/freecity-assets/blob/main/tokens/bkc/0xe5c229095b375e792e76b6efe5d4670d4118cf1f.png?raw=true',
    },
    {
      name: 'dBTC',
      symbol: 'dBTC',
      decimals: 18,
      address: '0xBfB0CAC38E1Dcf2B8022f1eeC9d939C63Bb39650',
      image: 'https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/tokens/naming/dbtc.png',
    },
    {
      name: 'DK (Dice Kingdom)',
      symbol: 'DK',
      decimals: 18,
      address: '0x8E3C2B00eFEBB64a6B775c451197a9dbA1077967',
      image: 'https://exchange.diamon.finance/images/coins/0x8E3C2B00eFEBB64a6B775c451197a9dbA1077967.png',
    },

    {
      name: 'Punk',
      symbol: 'PUNK',
      decimals: 18,
      address: '0x35fe7fFD1B3d80D0B07E16F51d8b630Bb9bEcB95',
      image: 'https://raw.githubusercontent.com/bl0ck0x88/freecity-assets/main/tokens/naming/punk.png',
    },
  ];

  nftAddresses = [
    {
      name: 'Dig Dragon',
      address: '0x7C80f994C724b0C8F834F4303C4f142004798219',
      image: '/assets/images/nfts/digdragon.jpeg',
    },
    {
      name: 'PunkKub',
      address: '0xfF2f5342FF8fFfA17a04aA5CbC162001A25B71a7',
      image: '/assets/images/nfts/punkkub.png',
    },
    {
      name: 'XRB Artist',
      address: '0x7b4451570327bf15Eca969508bC5c8115E19AC25',
      image: '/assets/images/nfts/artist.png',
    },
    {
      name: 'XRB Spiritual',
      address: '0xc146Cb6D1B844e998f2EB803626d13377F3BfCF0',
      image: '/assets/images/nfts/spiritual.png',
    },
    {
      name: 'XRB Strength',
      address: '0x80B302473c410234cF05D8cF6F1e1c41418C2788',
      image: '/assets/images/nfts/strength.png',
    },
  ];

  constructor(
    public generalService: GeneralService,
    public singletonService: SingletonService
  ) {}

  ngOnInit(): void {}
}
