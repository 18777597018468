<button *ngIf="!account" class="btn btn-gradient" (click)="connect()">Connect Wallet</button>

<button *ngIf="account" class="btn btn-outline-success" (click)="disconnect(disconnectModal)">{{account | hideAddress}}</button>



<ng-template #disconnectModal let-modal>

    <div class="text-right" style="margin-right: 15px; margin-top: 15px">
        <button type="button" class="close text-white-main" (click)="modal.close('close')" aria-label="Close">
            <span aria-hidden="true" class="close-sign" >x</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="text-center">
            <p>
                <i data-feather="meh" class="font-large-3 text-white-sub"></i>
            </p>
            <p>Are you sure you want to disconnect ?</p>
        </div>
    </div>
    <div class="text-center mb-3">
        <button   class=" btn btn-outline-danger mr-1" (click)="modal.close('close')">
            Cancel
        </button>
        <button class=" btn btn-gradient"
           (click)="modal.close('disconnect')">
            Yes, back here soon
        </button>
    </div>
</ng-template>
