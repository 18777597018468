import { Injectable } from '@angular/core';
import { SingletonService } from './singleton.service';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import ERC721ENUMERABLE_ABI from '../abis/ERC721Enumerable.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import IERC20_ABI from '../abis/IERC20.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import AIRDROP_STATION_ABI from '../abis/AirdropStation.json';
import { ethers } from 'ethers';
import { Logger } from './logger.service';

const log = new Logger('ContractService');

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  constructor(private singletonService: SingletonService) {}

  async getAllowance(tokenAddress, ownerAddress, spenderAddress) {
    const publicProvider = await this.singletonService.getPublicProvider();
    const token = await new ethers.Contract(tokenAddress, IERC20_ABI, publicProvider);
    return await token.allowance(ownerAddress, spenderAddress);
  }

  async approve(tokenAddress, spenderAddress, amountString) {
    const provider = await this.singletonService.getProvider();
    const token = await new ethers.Contract(tokenAddress, IERC20_ABI, provider.getSigner());
    return await token.approve(spenderAddress, amountString);
  }

  async getAirdropAddress() {
    const airdropStationAddress =
      (await this.singletonService.getChainId()) === 96
        ? '0xC4dBC25Fe7F68Fd8a96aB3fa170b27AbaF9545a8'
        : '0x16e97405C2ebB35aD7bFA81c0D3075BCA3A46954';
    return airdropStationAddress;
  }

  async airdrop(tokenAddress, walletAddresses, amounts) {
    const provider = await this.singletonService.getProvider();
    const airdropStationAddress = await this.getAirdropAddress();
    const airdropStation = await new ethers.Contract(airdropStationAddress, AIRDROP_STATION_ABI, provider.getSigner());
    return airdropStation.airdrop(tokenAddress, walletAddresses, amounts, { value: ethers.utils.parseEther('1') });
  }

  async getNFTHolders(nftAddress, start = 1) {
    const publicProvider = await this.singletonService.getPublicProvider();
    const nft = await new ethers.Contract(nftAddress, ERC721ENUMERABLE_ABI, publicProvider);
    const holders = [];
    const total = await nft.totalSupply();
    log.debug('total supply ' + total);
    const calls = [];
    for (let i = start; i < total + start; i++) {
      calls.push(nft.ownerOf(i));
    }
    const holderAddresses = await Promise.all(calls);
    console.log(holderAddresses);
    for (let i = start; i < total + start; i++) {
      holders.push({
        id: i,
        holder: holderAddresses[i],
      });
    }
    return holders;
  }
}
