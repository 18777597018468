import { Component, OnInit } from '@angular/core';
import {ContractService} from "../../services/contract.service";

@Component({
  selector: 'app-nft-holders',
  templateUrl: './nft-holders.component.html',
  styleUrls: ['./nft-holders.component.scss']
})
export class NftHoldersComponent implements OnInit {

  holders;
  constructor(private contractService:ContractService) { }

  async ngOnInit(){
    //const result = await this.contractService.getNFTHolders("0xc146Cb6D1B844e998f2EB803626d13377F3BfCF0")
    //console.log(result)
  }

}
