import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";

@Component({
  selector: 'app-connected-network',
  templateUrl: './connected-network.component.html',
  styleUrls: ['./connected-network.component.scss']
})
export class ConnectedNetworkComponent implements OnInit {

  isSupportNetwork = true;
  chainName = ''
  chainInfo;

  constructor(private singletonService:SingletonService) { }

  async ngOnInit(){
    await this.refresh()
    this.singletonService.getChainSubject().subscribe(async (chain) => {
      await this.refresh()
    })
  }

  async refresh(){
    this.chainInfo = await this.singletonService.getChainInfo()
    if(this.chainInfo){
      this.chainName = this.chainInfo.chainName
      this.isSupportNetwork = true
    }else{
      this.chainName = "Not Bitkub Network"
      this.isSupportNetwork = false
    }
  }
}
