import { Component, OnInit } from '@angular/core';
import {ethers} from "ethers";
import {ActivatedRoute, Router} from "@angular/router";
import {GeneralService} from "../../services/general.service";

@Component({
  selector: 'app-block-countdown',
  templateUrl: './block-countdown.component.html',
  styleUrls: ['./block-countdown.component.scss']
})
export class BlockCountdownComponent implements OnInit {

  remainBlock
  block
  targetDate
  hasResult = false
  currentBlock
  currentDate
  loading = true;
  baseUrl
  publicProvider

  constructor(private _router: Router, private route: ActivatedRoute, public generalService:GeneralService) {
    this.route.queryParams.subscribe(params => {
      this.block = params['block'];
    });
  }

  async ngOnInit() {
    this.publicProvider = await new ethers.providers.JsonRpcProvider("https://rpc.bitkubchain.io")
    this.hasResult = false
    let currentAbsoluteUrl = window.location.href;
    let currentRelativeUrl = this._router.url;
    let index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    this.baseUrl = currentAbsoluteUrl.substring(0, index);
    if(this.block){
      await this.calculate()
    }
  }

  async calculate(){
    if(this.block){
      this.currentBlock = await this.publicProvider.getBlockNumber();
      this.currentDate = new Date()

      this.remainBlock  = this.block - this.currentBlock
      this.targetDate = (new Date(this.currentDate.getTime()+(this.remainBlock*5*1000)))
      this.loading = false
      this.hasResult = true
    }

  }

}
