<p>token-creation works!</p>
<div class="row">
    <div class="col-12 mb-1">
        <div class="form-group">
            <label for="tokenName">Token Name</label>
            <input type="text" class="form-control input-dark" id="tokenName" [(ngModel)]="tokenName"
                   placeholder="Token Name"/>
        </div>
    </div>

    <div class="col-12 mb-1">
        <div class="form-group">
            <label for="tokenSymbol">Token Symbol</label>
            <input type="text" class="form-control input-dark" id="tokenSymbol" [(ngModel)]="tokenSymbol"
                   placeholder="Token Symbol"/>
        </div>
    </div>

    <div class="col-12 mb-1">
        <button (click)="generate()" class="btn btn-gradient">Generate</button>
    </div>
    <div class="col-12 mb-1">
        <div *ngIf="generatedCode">
            <h4>Generated Contract Code:</h4>
            <pre>{{ generatedCode }}</pre>
        </div>
    </div>

    <div *ngIf="compilationErrors">
        <h4>Compilation Errors:</h4>
        <pre>{{ compilationErrors }}</pre>
    </div>

</div>
