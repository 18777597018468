
<div class="row">

    <div class="col-md-12">
        <h3 class="d-inline-block text-gradient">Address Validator</h3>
    </div>

    <div class="col-md-8">
        <p>
            You can validate an address which valid or not and convert to actual, lower and upper case.
        </p>
        <hr/>


        <div class="input-group  ">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(addr)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    type="text"
                    class="form-control"
                    id="bit"
                    placeholder="Target Address"
                    (change)="calculate()"
                    (paste)="calculate()"
                    (input)="calculate()"
                    [(ngModel)]="addr"
            />

        </div>
        <div>

            <span *ngIf="!isValid && addr != ''" class="text-danger">&#10005; Invalid address</span>
            <span *ngIf="isValid && addr != ''" class="text-success">&#x2713; Valid address</span>
        </div>

        <div class="mt-2 mb-2">
            <strong class="text-primary" >Actual Case</strong>
        </div>
        <div class="input-group mb-2  ">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(actualCase)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    disabled
                    type="text"
                    class="form-control"
                    id="actual"
                    placeholder="Actual"
                    [(ngModel)]="actualCase"
            />

        </div>

        <div class="mt-2 mb-2">
            <strong class="text-primary" >Lower Case</strong>
        </div>
        <div class="input-group   mb-2">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(lowerCase)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    disabled
                    type="text"
                    class="form-control"
                    id="gbit"
                    placeholder="Lower Case"
                    [(ngModel)]="lowerCase"
            />

        </div>

        <div class="mt-2 mb-2">
            <strong class="text-primary" >Upper Case</strong>
        </div>
        <div class="input-group   mb-2">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(upperCase)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    disabled
                    type="text"
                    class="form-control"
                    id="kub"
                    placeholder="Upper Case"
                    [(ngModel)]="upperCase"
            />

        </div>
    </div>

    <div class="col-md-4">
        <img src="./assets/images/3d/3.png" width="100%"  >
    </div>


</div>
