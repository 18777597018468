<div class="row">
    <div class="col-12">
        <h3 class="text-gradient d-inline-block">Networks</h3>
        <p class="">In Bitkub Chain, there are 2 networks, main network and test network. You can add the network here by one click.</p>
    </div>

    <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let network of networks">
        <div class="card">

            <div class="card-body">
                <div class="card-row d-flex justify-content-between align-items-center"  >
                    <div class="media">
                        <div class="  mr-75">
                            <img src="assets/images/networks/{{network.networkAbbr}}.png" width="32px" class="rounded " style="margin-top: 6px"/>
                        </div>
                        <div class="media-body my-auto">
                            <h6 class="mb-0 font-weight-bold">{{network.chainName}}</h6>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="isConnected && chainId == network.chainIdNumber">
                        <div class="badge badge-pill badge-success" >Current</div>
                    </div>
                </div>
                <div class="card-row"  >
                    <div class="action row">
                        <div class="col-6">
                            <button class="btn btn-gradient btn-md btn-block" *ngIf="account" (click)="addNetwork(network)">
                                Add
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-gradient btn-md  btn-block" *ngIf="account" (click)="switchNetwork(network)">
                                Switch
                            </button>
                        </div>
                        <div class="col-12" *ngIf="!account">
                            <span class="font-italic" >Please connect your wallet</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-muted font-small-2 mt-50"  >
                    <div>Network Name</div>
                    <div>{{network.chainName}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-muted font-small-2 mt-50"  >
                    <div>Chain ID</div>
                    <div>{{network.chainIdNumber}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-muted font-small-2 mt-50"  >
                    <div>Currency Symbol</div>
                    <div>{{network.nativeCurrency.symbol}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-muted font-small-2 mt-50"  >
                    <div>RPC URL</div>
                    <div>{{network.rpcUrls[0]}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-muted font-small-2 mt-50"  >
                    <div>WSS</div>
                    <div>{{network.wssUrls[0]}}</div>
                </div>
                <div class="d-flex justify-content-between align-items-center text-muted font-small-2 mt-50"  >
                    <div>Block Explorer URL</div>
                    <div><a class="text-muted" [href]="network.blockExplorerUrls[0]" target="_blank">{{network.blockExplorerUrls[0]}}</a></div>
                </div>

            </div>
        </div>
    </div>
</div>
