import { FormsModule } from '@angular/forms';


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { CountdownComponent } from './countdown/countdown.component';
import { ConnectWalletComponent } from './connect-wallet/connect-wallet.component';
import {CorePipesModule} from "../../@core/pipes/pipes.module";
import { ConnectedNetworkComponent } from './connected-network/connected-network.component';

@NgModule({
  declarations: [

    CountdownComponent,
     ConnectWalletComponent,
     ConnectedNetworkComponent,

  ],
  exports: [

    CountdownComponent,
    ConnectWalletComponent,
    ConnectedNetworkComponent,

  ],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    TranslateModule,
    FormsModule,
    CorePipesModule
  ],
})
export class SharedComponentsModule { }
