<!--<div class="row">
    <div class="col-12 text-center">
        <img src="assets/images/3d/2.png" width="300px"/>
    </div>
    <div class="col-12 text-center">
        <h1 class="mt-3 text-gradient d-inline-block">Coming Soon</h1>
        <p>Stay tuned, under development process.</p>
    </div>
</div>
-->

<div class="row">

    <div class="col-md-12">
        <h3 class="d-inline-block text-gradient">Unit Converter</h3>
    </div>

    <div class="col-md-8">
        <p>
           You can use this tool to convert unit.
        </p>
        <hr/>


        <div class="input-group   mb-2">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(bit)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    type="text"
                    class="form-control"
                    id="bit"
                    placeholder="BIT/Wei"
                    (change)="calculate('bit')"
                    (paste)="calculate('bit')"
                    (input)="calculate('bit')"
                    [(ngModel)]="bit"
            />
            <div class="input-group-append" >
                <span class="input-group-text font-small-2" style="width: 120px">BIT/Wei (10<sup>-18</sup>)</span>
            </div>
        </div>

        <div class="input-group   mb-2">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(gbit)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    type="text"
                    class="form-control"
                    id="gbit"
                    placeholder="GBIT/GWei"
                    (change)="calculate('gbit')"
                    (paste)="calculate('gbit')"
                    (input)="calculate('gbit')"
                    [(ngModel)]="gbit"
            />
            <div class="input-group-append" >
                <span class="input-group-text font-small-2" style="width: 120px">GBIT/GWei (10<sup>-9</sup>)</span>
            </div>
        </div>

        <div class="input-group   mb-2">
            <div class="input-group-prepend"  (click)="generalService.addToClipboard(kub)">
                <span class="input-group-text cursor-pointer" ><i data-feather="copy" ></i></span>
            </div>
            <input
                    type="text"
                    class="form-control"
                    id="kub"
                    placeholder="KUB"
                    (change)="calculate('kub')"
                    (paste)="calculate('kub')"
                    (input)="calculate('kub')"
                    [(ngModel)]="kub"
            />
            <div class="input-group-append" >
                <span class="input-group-text font-small-2" style="width: 120px">KUB</span>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <img src="./assets/images/3d/3.png" width="100%"  >
    </div>


</div>
