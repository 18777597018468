import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ethers } from 'ethers';
import { Logger } from './logger.service';
import Swal from 'sweetalert2';

const log = new Logger('SingletonService');

@Injectable({
  providedIn: 'root',
})
export class SingletonService {
  public provider: any;
  public publicProvider: any;

  public account: any;
  public accountSubject = new Subject<any>();

  public chain: any;
  public chainSubject = new Subject<any>();

  public currentChain: Observable<any>;
  public currentChainSubject: BehaviorSubject<any>;

  constructor() {}

  getChainSubject(): Observable<any> {
    return this.chainSubject.asObservable();
  }

  getAccountSubject(): Observable<any> {
    return this.accountSubject.asObservable();
  }

  getProvider() {
    return this.provider;
  }

  async connectWallet() {
    await localStorage.setItem('connectStatus', '1');
    const _account = await this.getAccount();
    this.provider = await new ethers.providers.Web3Provider(window.ethereum, 'any');
    await this.updateConnectedWallet(_account);
    return _account;
  }

  async disconnectWallet() {
    await localStorage.setItem('connectStatus', '0');
    this.accountSubject.next(null);
    this.account = null;
  }

  async getEnableWallet() {
    return localStorage.getItem('connectStatus') === '1';
  }

  async getAccount(debug = '-1') {
    const enableWallet = localStorage.getItem('connectStatus');
    if (enableWallet === '1') {
      if (!this.provider) {
        await this.initProvider();
      }
      this.account = await this.getCurrentConnectedAccount();
    }
    log.debug('return account ', this.account);
    return this.account;
  }

  async getCurrentConnectedAccount() {
    try {
      if (this.provider) {
        const _accounts = await this.provider.send('eth_requestAccounts', []);
        return _accounts[0];
      }
    } catch (error) {
      log.error(`error on get currentConnectedAccount`, error);
      await this.fire('error', 'Something wrong!', error.message);
    }
  }

  async getPublicProvider() {
    const chainInfo = await this.getChainInfo();
    let rpcUrl = 'https://rpc.bitkubchain.io/';
    if (chainInfo) {
      rpcUrl = chainInfo.rpcUrls[0];
    }
    this.publicProvider = await new ethers.providers.JsonRpcProvider(rpcUrl);
    return this.publicProvider;
  }

  async initProvider() {
    log.debug('Init provider');
    this.provider = await new ethers.providers.Web3Provider(window.ethereum, 'any');
    this.provider.on('network', (newNetwork, oldNetwork) => {
      log.debug('network changed');
      if (oldNetwork) {
        log.debug('Chain changed from ' + oldNetwork.chainId + ' to ' + newNetwork.chainId);
        this.chainSubject.next(newNetwork);
      }
    });
    // TODO : init public provider
  }

  async updateConnectedWallet(walletAddress: any) {
    log.debug('updateConnectedWallet walletAddress => %o', walletAddress);

    this.account = walletAddress;
    this.accountSubject.next(walletAddress);
    //await this.getCurrentChain()
  }

  async getChainId() {
    try {
      this.provider = await new ethers.providers.Web3Provider(window.ethereum, 'any');
      const _chainId = await this.provider.getNetwork();
      return _chainId.chainId;
    } catch (e) {
      log.error(e);
      return 0;
    }
  }

  async getChainName() {
    const id = await this.getChainId();
    const chains = await this.getSupportChains();
    const chain = chains.find(ch => {
      return ch.chainIdNumber == id;
    });
    if (chain) {
      return chain.chainName;
    } else {
      return 'Not Bitkub Network';
    }
  }

  async getChainInfo() {
    const id = await this.getChainId();
    const chains = await this.getSupportChains();
    const chain = chains.find(ch => {
      return ch.chainIdNumber == id;
    });
    return chain;
  }

  async isSupportNetwork() {
    const id = await this.getChainId();
    const chains = await this.getSupportChains();
    const chain = chains.find(ch => {
      return ch.chainIdNumber == id;
    });
    if (chain) {
      return true;
    } else {
      return false;
    }
  }

  async fire(type = 'error', title = 'Error', text = '') {
    // @ts-ignore
    Swal.fire({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 5000,
      icon: type,
      title: title,
      text: text,
    });
  }

  async getSupportChains() {
    return [
      {
        networkAbbr: 'bkc',
        chainIdNumber: 96,
        chainId: '0x' + (96).toString(16),
        chainName: 'Bitkub Chain Mainnet',
        rpcUrls: ['https://rpc.bitkubchain.io/'],
        nativeCurrency: {
          name: 'KUB COIN',
          symbol: 'KUB',
          decimals: 18,
        },
        wssUrls: ['wss://wss.bitkubchain.io'],
        blockExplorerUrls: ['https://bkcscan.com/'],
      },
      {
        networkAbbr: 'bkctestnet',
        chainIdNumber: 25925,
        chainId: '0x' + (25925).toString(16),
        chainName: 'Bitkub Chain Testnet',
        rpcUrls: ['https://rpc-testnet.bitkubchain.io'],
        nativeCurrency: {
          name: 'KUB COIN',
          symbol: 'tKUB',
          decimals: 18,
        },
        wssUrls: ['wss://wss-testnet.bitkubchain.io'],
        blockExplorerUrls: ['https://testnet.bkcscan.com/'],
      },
    ];
  }

  async addToMetamask(tokenInfo) {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenInfo.address, // The address that the token is at.
            symbol: tokenInfo.symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenInfo.decimals, // The number of decimals in the token
            image: tokenInfo.image, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }
  }
}
