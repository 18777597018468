import { Component, OnInit } from '@angular/core';
import {GeneralService} from "../../services/general.service";
import {ethers} from "ethers";

@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.scss']
})
export class ValidatorComponent implements OnInit {

  addr = ''
  lowerCase = ''
  upperCase = ''
  actualCase = ''
  isValid = false

  constructor(public generalService:GeneralService) { }

  ngOnInit(): void {
  }

  calculate(){
    this.lowerCase = this.addr.toLowerCase()
    this.upperCase = this.addr.toUpperCase()
    try{
      let r = ethers.utils.getAddress(this.lowerCase)
      this.actualCase = r
      this.isValid = true
      console.log(r)
    }catch (e) {
      this.isValid = false
      console.log(e)
    }


  }

}
