import { Component, OnInit } from '@angular/core';
import {GeneralService} from "../../services/general.service";
import {BigNumber, ethers} from "ethers";

@Component({
  selector: 'app-unit-converter',
  templateUrl: './unit-converter.component.html',
  styleUrls: ['./unit-converter.component.scss']
})
export class UnitConverterComponent implements OnInit {

  bit = '1000000000000000000'
  gbit = '1000000000'
  kub = '1'


  constructor(public generalService:GeneralService) { }

  ngOnInit() {
  }

  calculate(type){
    if(type == 'bit'){
      console.log("bit "+this.bit)
      this.kub = ethers.utils.formatUnits(this.bit, 'ether')
      this.gbit = ethers.utils.formatUnits(this.bit, 'gwei')
    }else if(type == 'gbit'){
      console.log("gbit "+this.gbit)
      let _gbit = ethers.utils.parseUnits(this.gbit, "gwei")
      this.kub = ethers.utils.formatUnits(_gbit, 'ether')
      this.bit = ethers.utils.formatUnits(_gbit, 'wei')
    }else{
      console.log("kub "+this.kub)
      let _kub = ethers.utils.parseUnits(this.kub, "ether")
      this.bit = ethers.utils.formatUnits(_kub, 'wei')
      this.gbit = ethers.utils.formatUnits(_kub, 'gwei')
    }

  }
}
