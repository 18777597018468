import { Component } from '@angular/core';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { CoreCommonModule } from '../../../@core/common.module';
import { Logger } from '../../services/logger.service';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { AirdropType } from '../../../@types/airdrop.type';
import { ContractService } from '../../services/contract.service';
import { SingletonService } from '../../services/singleton.service';
import { ethers } from 'ethers';
import { ToastrService } from 'ngx-toastr';

const logger = new Logger('TokenAirdropComponent');
enum STATE {
  PENDING,
  PROCESSED,
  DONE,
}
@Component({
  selector: 'app-token-airdrop',
  standalone: true,
  imports: [CodemirrorModule, CoreCommonModule, NgbAlertModule],
  templateUrl: './token-airdrop.component.html',
  styleUrls: ['./token-airdrop.component.scss'],
})
export class TokenAirdropComponent {
  content: string = '0x354c66affdF129a6e0f53F16f1DF736c79da9fFF,1\n0xF253Af73c95071Fef387EF71d60f95017858979E,2';
  errors: string = '';
  isToken: boolean = false;
  tokenAddress: string = '';
  distributions: AirdropType[] = [];
  total: number = 0;
  state = STATE.PENDING;
  shouldApprove = true;
  shouldApproveAmount = '';
  isLoading = false;
  txHash: string = '';
  protected readonly STATE = STATE;

  constructor(
    private singletonService: SingletonService,
    private contractService: ContractService,
    private toastr: ToastrService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async onContentChange(event: any) {
    const lines = this.content.split('\n');
    let isValid = true;
    this.clearError();
    for (const [index, line] of lines.entries()) {
      let error = '';
      if (!line) continue; // skip empty lines
      const [walletAddress, tokenAmount] = line.split(',');
      if (!this.isValidWalletAddress(walletAddress) || !this.isValidTokenAmount(tokenAmount)) {
        isValid = false;
      }
      if (!this.isValidWalletAddress(walletAddress)) {
        error += `invalid wallet address `;
      }
      if (!this.isValidTokenAmount(tokenAmount)) {
        error += `invalid token amount `;
      }
      if (error && error !== '') {
        error = `line ${index + 1} : ${error}`;
      }
      await this.addError(error);
    }

    // You can use isValid to show feedback in the UI
    logger.debug('Is valid:', isValid);
    // Implement further actions based on the validation result
  }

  clearError() {
    this.errors = '';
  }

  async addError(error: string) {
    if (this.errors !== '') {
      this.errors += '<br/>';
    }
    this.errors += error;
  }

  isValidWalletAddress(address: string): boolean {
    // Implement your wallet address validation logic here
    // For Ethereum, addresses should be 42 characters long including the '0x'
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  }

  isValidTokenAmount(amount: string): boolean {
    // Implement your token amount validation logic here
    // Example: Check if it's a positive number
    const num = Number(amount);
    return !isNaN(num) && num > 0;
  }

  checkIsToken() {
    logger.debug('check is token');
    // TODO : Use isValidWalletAddress to verify and call to blockchain
    this.isToken = true;
  }

  async processData() {
    this.isLoading = true;
    if (this.isToken && !this.errors) {
      logger.debug('process');
      const data: AirdropType[] = [];
      const lines = this.content.split('\n');
      this.clearError();
      for (const [index, line] of lines.entries()) {
        if (!line) continue; // skip empty lines
        const [walletAddress, tokenAmount] = line.split(',');
        data.push({ walletAddress, tokenAmount: Number(tokenAmount), sequence: index });
        this.total += Number(tokenAmount);
      }
      this.distributions = data;
      const account = await this.singletonService.getAccount();
      const allowance = await this.contractService.getAllowance(
        this.tokenAddress,
        account,
        this.contractService.getAirdropAddress()
      );

      this.shouldApprove = allowance.lt(ethers.utils.parseEther(`${this.total}`));
      this.shouldApproveAmount = ethers.utils.parseEther(`${this.total}`).toString();
      logger.debug(`should approve ${this.shouldApprove}, amount ${this.shouldApproveAmount}`);
      this.state = STATE.PROCESSED;
    }
    this.isLoading = false;
  }

  async approve() {
    this.isLoading = true;
    this.contractService
      .approve(this.tokenAddress, this.contractService.getAirdropAddress(), this.shouldApproveAmount)
      .then(async transaction => {
        logger.debug(`send transaction ${transaction}`);
        const receipt = await transaction.wait();
        logger.debug('receipt => ', receipt);
        this.shouldApprove = false;
      })
      .catch(error => {
        logger.error(`error ${error}`);
        this.toastr.error('Error ', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async airdrop() {
    this.isLoading = true;
    this.contractService
      .airdrop(
        this.tokenAddress,
        this.distributions.map(distribution => distribution.walletAddress),
        this.distributions.map(distribution => ethers.utils.parseEther(`${distribution.tokenAmount}`))
      )
      .then(async transaction => {
        logger.debug(`send transaction ${transaction}`);
        const receipt = await transaction.wait();
        logger.debug('receipt => ', receipt);
        this.txHash = receipt.transactionHash;
        this.state = STATE.DONE;
      })
      .catch(error => {
        logger.error(`error ${error}`);
        this.toastr.error('Error ', error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
