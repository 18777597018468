import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-connect-wallet',
  templateUrl: './connect-wallet.component.html',
  styleUrls: ['./connect-wallet.component.scss']
})
export class ConnectWalletComponent implements OnInit {

  account

  constructor(private modalService: NgbModal,private singletonService:SingletonService) { }

  async ngOnInit() {
    this.account = await this.singletonService.getAccount()
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      this.account = account
    })
  }

  async connect(){
    await this.singletonService.connectWallet()
  }

  async disconnect(confirmModal){
    await this.fireDisconnectModal(confirmModal)
  }

  async fireDisconnectModal(content) {
    this.modalService.open(content, {animation: true}).result.then(async (result) => {
      if (result == "disconnect") {
        await this.singletonService.disconnectWallet()
      }
    }).catch((res) => {
    });
  }
}
