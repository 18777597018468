
<div class="row">
    <div class="col-12">
        <h3 class="text-gradient d-inline-block">Block Calculator</h3>
        <p class="">Block number calculation on the BitKub chain, select the date and time you want to know a block number and hit calculate button.</p>
    </div>

    <div class="col-md-12">
        <div class="mt-3">
            <input [(ngModel)]="date" class="datetime-picker" step="1" type="datetime-local"   />
            <a (click)="calculate()" class="calculate-btn ml-1 btn button-gradient button-two" style="margin-top: -5px">Calculate</a>

        </div>

        <div *ngIf="block" class="mt-3" >
            Block number
            <h1 >
                {{block | number:'1.0-0'}}
            </h1>
            <span>Without comma : {{block}} <a (click)="generalService.addToClipboard(block)" class="btn p-0 " style="margin-top: -4px; margin-left: 5px">
            <i data-feather="copy" class="font-small-4 text-default"></i>
        </a></span>
        </div>


    </div>
    <div class="col-md-6">
        <div class="mt-3 text-center" >
            <table class="result-table"  >
                <tr>
                    <td class="text-left">Selected date </td>
                    <td class="text-right">{{date ? (date | date:'medium') : '-'}}</td>
                </tr>

                <tr>
                    <td class="text-left">Current date </td>
                    <td class="text-right">{{currentDate ? (currentDate | date:'medium') : '-'}}</td>
                </tr>
                <tr>
                    <td class="text-left">Result block</td>
                    <td class="text-right">{{block }}</td>
                </tr>
                <tr>
                    <td class="text-left">Current block</td>
                    <td class="text-right">{{currentBlock}}</td>
                </tr>
                <tr>
                    <td class="text-left">Block timestamp</td>
                    <td class="text-right">{{blockTimestamp | date:'medium'}}</td>
                </tr>
                <tr>
                    <td class="text-left">Block unix timestamp</td>
                    <td class="text-right">{{blockUnixTimestamp}}</td>
                </tr>
                <tr>
                    <td class="text-left">Block different</td>
                    <td class="text-right">{{block - currentBlock}}</td>
                </tr>
            </table>


        </div>
    </div>
</div>




<div class="content">







</div>
