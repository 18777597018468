import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'About KUB Tools',
    translate: 'MENU.ABOUT_US',
    type: 'item',
    url: 'about-us',
  },
  {
    id: 'project-tools-section',
    type: 'section',
    title: 'Project Tools',
    translate: 'MENU.PROJECT_TOOLS',
    children: [
      {
        id: 'airdrop',
        title: 'Token Airdrop',
        translate: 'MENU.TOKEN_AIRDROP',
        type: 'item',
        url: 'token-airdrop',
        badge: {
          title: 'New',
          translate: 'MENU.NEW',
          classes: 'badge-light-danger badge-pill',
        },
      },
      {
        id: 'airdrop',
        title: 'Launchpads',
        translate: 'MENU.TOKEN_LAUNCHPAD',
        type: 'item',
        url: 'token-launchpad',
        badge: {
          title: 'SOON',
          translate: 'MENU.SOON',
          classes: 'badge-light-warning badge-pill',
        },
      },
    ],
  },
  {
    id: 'address-section',
    type: 'section',
    title: 'Address',
    translate: 'MENU.ADDRESS',
    children: [
      {
        id: 'address-book',
        title: 'Address Book',
        translate: 'MENU.ADDRESS_BOOK',
        type: 'item',
        url: 'address-book',
      },
      {
        id: 'validator',
        title: 'Address Validator',
        translate: 'MENU.VALIDATOR',
        type: 'item',
        url: 'validator',
        /*badge: {
                    title: 'New',
                    translate: 'MENU.NEW',
                    classes: 'badge-light-danger badge-pill'
                }*/
      },
    ],
  },
  /* {
        id: 'creation-section',
        type: 'section',
        title: 'Creation',
        translate: 'MENU.CREATION',
        children: [
            {
                id: 'token-creation',
                title: 'Token Creation',
                translate: 'MENU.TOKEN_CREATION',
                type: 'item',
                url: 'token-creation',
                badge: {
                    title: 'Beta',
                    translate: 'MENU.BETA',
                    classes: 'badge-light-danger badge-pill'
                }
            }
        ]
    },*/
  {
    id: 'block-section',
    type: 'section',
    title: 'Block',
    translate: 'MENU.BLOCK',
    children: [
      {
        id: 'block-calculator',
        title: 'Block Calculator',
        translate: 'MENU.BLOCK_CALCULATOR',
        type: 'item',
        url: 'block-calculator',
      },
      {
        id: 'block-countdown',
        title: 'Block Countdown',
        translate: 'MENU.BLOCK_COUNTDOWN',
        type: 'item',
        url: 'block-countdown',
      },
    ],
  },
  {
    id: 'other-section',
    type: 'section',
    title: 'Other',
    translate: 'MENU.OTHER',
    children: [
      {
        id: 'networks',
        title: 'Networks',
        translate: 'MENU.NETWORK',
        type: 'item',
        url: 'networks',
        /*badge: {
                  title: 'New',
                  translate: 'MENU.NEW',
                  classes: 'badge-light-danger badge-pill'
                }*/
      },
      {
        id: 'unit-converter',
        title: 'Unit Converter',
        translate: 'MENU.UNIT_CONVERTER',
        type: 'item',
        url: 'unit-converter',
      },
    ],
  },
];
