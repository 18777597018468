export const locale = {
  lang: 'en',
  data: {
    MENU: {
      ABOUT_US: 'About KUB Tools',
      ADDRESS: 'Address',
      ADDRESS_BOOK: 'Address Book',
      BETA: 'Beta',
      BLOCK: 'Block',
      BLOCK_CALCULATOR: 'Block Calculator',
      BLOCK_COUNTDOWN: 'Block Countdown',
      CREATION: 'Creation',
      DASHBOARD: 'Dashboard',
      HOME: 'Home',
      NETWORK: 'Network',
      NEW: 'New',
      OTHER: 'Other',
      PROJECT_TOOLS: 'Project Tools',
      SAMPLE: 'Sample',
      SOON: 'Soon',
      TOKEN_AIRDROP: 'Token Airdrop',
      TOKEN_LAUNCHPAD: 'Launchpads',
      TOKEN_CREATION: 'Token Creation',
      UNIT_CONVERTER: 'Unit Converter',
      UPDATE: 'Update',
      VALIDATOR: 'Address Validator',
    },
  },
};
