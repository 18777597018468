import { Component } from '@angular/core';

@Component({
  selector: 'app-token-launchpad',
  standalone: true,
  imports: [],
  templateUrl: './token-launchpad.component.html',
  styleUrls: ['./token-launchpad.component.scss'],
})
export class TokenLaunchpadComponent {}
