<!-- <div class="timer">
  <span id="days"> {{daysToDday}} </span>day<span *ngIf="daysToDday > 1">s</span>
  <span id="hours"> {{hoursToDday}} </span>:
  <span id="minutes"> {{minutesToDday}} </span>:
  <span id="seconds"> {{secondsToDday}} </span>
</div> -->
<div class="spinner" *ngIf="loading">
  <div class="inner-circle"></div>
</div>


<div *ngIf="!loading">
  <div class="timer d-inline-block" *ngIf="timeDifference >= 0 || (!isShowFinishText && timeDifference < 0)">
    <span id="days"> {{daysToDday}} </span>day<span *ngIf="daysToDday > 1">s</span>
    <span id="hours"> {{hoursToDday | number:"2.0-0"}} </span>:
    <span id="minutes"> {{minutesToDday | number:"2.0-0"}} </span>:
    <span id="seconds"> {{secondsToDday | number:"2.0-0"}} </span>
  </div>
  <div class="timer d-inline-block" *ngIf="isShowFinishText && timeDifference < 0">
    {{finishTextDisplay}}
  </div>
</div>

