<div class="row">

    <div class="col-md-12">
        <h3 class="d-inline-block text-gradient">Address Book</h3>
    </div>

    <div class="col-md-8">
        <p>
            Gather often-use addresses here. You can add token to your <img src="assets/images/wallets/metamask.png" alt="metamask" width="24" style="display: inline-block" /> wallet
            by connect your wallet and clicking on <i data-feather="plus" class="font-small-3 text-default"></i> sign.
        </p>
        <hr/>

        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        Official Tokens
                    </div>

                    <div class="card-body">
                        <div class="card-row d-flex justify-content-between align-items-center"  *ngFor="let elem of officialTokenAddresses">
                            <div class="media">
                                <div class="  mr-75">
                                    <img [src]="elem.image" width="24px" class="rounded " style="margin-top: 6px"/>
                                </div>
                                <div class="media-body my-auto">
                                    <h6 class="mb-0 font-weight-bold">{{elem.name}}</h6>
                                    <small class="text-muted font-small-2">
                                        <a href="https://www.bkcscan.com/address/{{elem.address}}" target="_blank">{{elem.address}}</a>
                                    </small>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">

                                <div class="action">
                                    <a (click)="generalService.addToClipboard(elem.address)" class="btn p-0 " title="Copy to clipboard" style="margin-top: -4px; margin-left: 5px">
                                        <i data-feather="copy" class="font-small-3 text-default"></i>
                                    </a>
                                    <a (click)="singletonService.addToMetamask(elem)" class="btn p-0 " alt="Add to Metamask" title="Add to Metamask" style="margin-top: -4px; margin-left: 5px">
                                        <i data-feather="plus" class="font-small-3 text-default"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        Tokens
                    </div>

                    <div class="card-body">
                        <div class="card-row d-flex justify-content-between align-items-center"  *ngFor="let elem of tokenAddresses">
                            <div class="media">
                                <div class="  mr-75">
                                    <img [src]="elem.image" width="24px" class="rounded " style="margin-top: 6px"/>
                                </div>
                                <div class="media-body my-auto">
                                    <h6 class="mb-0 font-weight-bold">{{elem.name}}</h6>
                                    <small class="text-muted font-small-2">
                                        <a href="https://www.bkcscan.com/address/{{elem.address}}" target="_blank">{{elem.address}}</a>
                                    </small>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">

                                <div class="action">
                                    <a (click)="generalService.addToClipboard(elem.address)" class="btn p-0 " title="Copy to clipboard"  style="margin-top: -4px; margin-left: 5px">
                                        <i data-feather="copy" class="font-small-3 text-default"></i>
                                    </a>
                                    <a (click)="singletonService.addToMetamask(elem)" class="btn p-0 " title="Add to Metamask" style="margin-top: -4px; margin-left: 5px">
                                        <i data-feather="plus" class="font-small-3 text-default"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="card">
                    <div class="card-header">
                        NFTs
                    </div>

                    <div class="card-body">
                        <div class="card-row d-flex justify-content-between align-items-center"  *ngFor="let elem of nftAddresses">
                            <div class="media">
                                <div class="avatar mr-75">
                                    <img [src]="elem.image" width="42px" class="rounded  " style="border: 1px solid #323232"/>
                                </div>
                                <div class="media-body my-auto">
                                    <h6 class="mb-0 font-weight-bold">{{elem.name}}</h6>
                                    <small class="text-muted font-small-2">
                                        <a href="https://www.bkcscan.com/address/{{elem.address}}" target="_blank">{{elem.address}}</a>
                                    </small>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">

                                <div class="action">
                                    <a (click)="generalService.addToClipboard(elem.address)" class="btn p-0 " style="margin-top: -4px; margin-left: 5px">
                                        <i data-feather="copy" class="font-small-3 text-default"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <img src="./assets/images/3d/5.png" width="100%"  >
    </div>


</div>
