import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast
import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { BlockCalculatorComponent } from './main/block-calculator/block-calculator.component';
import { BlockCountdownComponent } from './main/block-countdown/block-countdown.component';
import { UnitConverterComponent } from './main/unit-converter/unit-converter.component';
import { AddressBookComponent } from './main/address-book/address-book.component';
import { NetworksComponent } from './main/networks/networks.component';
import { ValidatorComponent } from './main/validator/validator.component';
import { NftHoldersComponent } from './main/nft-holders/nft-holders.component';
import { TokenCreationComponent } from './main/token-creation/token-creation.component';
import { TokenAirdropComponent } from './main/token-airdrop/token-airdrop.component';
import { TokenLaunchpadComponent } from './main/token-launchpad/token-launchpad.component';

const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: '',
    redirectTo: '/about-us',
    pathMatch: 'full',
  },
  {
    path: 'home',
    redirectTo: '/about-us',
  },
  {
    path: 'about-us',
    component: DashboardComponent,
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'address-book',
    component: AddressBookComponent,
    loadChildren: () => import('./main/address-book/address-book.module').then(m => m.AddressBookModule),
  },
  {
    path: 'block-calculator',
    component: BlockCalculatorComponent,
    loadChildren: () => import('./main/block-calculator/block-calculator.module').then(m => m.BlockCalculatorModule),
  },
  {
    path: 'block-countdown',
    component: BlockCountdownComponent,
    loadChildren: () => import('./main/block-countdown/block-countdown.module').then(m => m.BlockCountdownModule),
  },
  {
    path: 'networks',
    component: NetworksComponent,
    loadChildren: () => import('./main/networks/networks.module').then(m => m.NetworksModule),
  },
  {
    path: 'nft-holders',
    component: NftHoldersComponent,
    loadChildren: () => import('./main/nft-holders/nft-holders.module').then(m => m.NftHoldersModule),
  },
  {
    path: 'token-creation',
    component: TokenCreationComponent,
    loadChildren: () => import('./main/token-creation/token-creation.module').then(m => m.TokenCreationModule),
  },
  {
    path: 'token-airdrop',
    component: TokenAirdropComponent,
    loadChildren: () => import('./main/token-airdrop/token-airdrop.module').then(m => m.TokenAirdropModule),
  },
  {
    path: 'unit-converter',
    component: UnitConverterComponent,
    loadChildren: () => import('./main/unit-converter/unit-converter.module').then(m => m.UnitConverterModule),
  },
  {
    path: 'token-launchpad',
    component: TokenLaunchpadComponent,
    loadChildren: () => import('./main/token-launchpad/token-launchpad.module').then(m => m.TokenLaunchpadModule),
  },
  {
    path: 'validator',
    component: ValidatorComponent,
    loadChildren: () => import('./main/validator/validator.module').then(m => m.ValidatorModule),
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error', //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}

declare global {
  interface Window {
    web3: any;
    ethereum: any;
  }
}
