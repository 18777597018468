<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    Version   {{ version }}

  </span>

</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

