import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-token-creation',
  templateUrl: './token-creation.component.html',
  styleUrls: ['./token-creation.component.scss']
})
export class TokenCreationComponent implements OnInit {

  tokenName:string;
  tokenSymbol:string;
  maxSupply;
  generatedCode:string;

  compilationErrors: string;

  constructor() { }

  ngOnInit(): void {
  }

  generate(){
    this.generatedCode = `// Smart contract code generated dynamically\n
    contract ${this.tokenName?.trim()} {\n
    \tstring public name = "${this.tokenName}";\n
    \tstring public symbol = "${this.tokenSymbol}";\n
    \t// Add more contract code\n
    }`;


  }

}
