import { Component, OnInit } from '@angular/core';
import {SingletonService} from "../../services/singleton.service";
import Swal from "sweetalert2";
import { Logger } from '../../services/logger.service';
import {ToastrService} from "ngx-toastr";
const logger = new Logger('NetworksComponent');
@Component({
  selector: 'app-networks',
  templateUrl: './networks.component.html',
  styleUrls: ['./networks.component.scss']
})
export class NetworksComponent implements OnInit {

  chainId;
  isConnected = false;
  account;
  networks;

  constructor(private toastr: ToastrService,private singletonService:SingletonService) { }

  async ngOnInit(){
    this.networks = await this.singletonService.getSupportChains()
    this.account = await this.singletonService.getAccount()
    this.isConnected = await this.singletonService.getEnableWallet()
    if(this.isConnected){
      this.chainId = await this.singletonService.getChainId()
    }
    this.singletonService.getAccountSubject().subscribe(async (account) => {
      this.account = account
    })
  }

  async switchNetwork(network){
    try {
      // check if the chain to connect to is installed
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainId }], // chainId must be in hexadecimal numbers
      });

      this.toastr.success('Success to switch to ' + network.chainName)
      this.chainId = await this.singletonService.getChainId()

    } catch (error) {
      const errorCode = error.data ? (error.data?.originalError ? error.data?.originalError?.code : 0 ) : 0
      if (error.code === 4902 || errorCode == 4902 || error.code === -32603 || errorCode == -32603) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: network.chainId,
              chainName: network.chainName,
              rpcUrls: network.rpcUrls,
              nativeCurrency: network.nativeCurrency,
              blockExplorerUrls: network.blockExplorerUrls,
            }],
          });
        } catch (addError) {
          logger.error("error on add chain: %o",addError);
        }
      }
    }
  }

  async addNetwork(network){
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: network.chainId,
          chainName: network.chainName,
          rpcUrls: network.rpcUrls,
          nativeCurrency: network.nativeCurrency,
          blockExplorerUrls: network.blockExplorerUrls,
        }],
      });


    } catch (addError) {
       logger.error("error on add chain: %o",addError);
    } finally {
      await this.recheckNetwork()
    }
  }

  async recheckNetwork(){
    this.isConnected = await this.singletonService.getEnableWallet()
    this.chainId = await this.singletonService.getChainId()
  }

}
