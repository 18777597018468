<div class="row">
  <div class="col-md-12">
    <h3 class="d-inline-block text-gradient">Token Airdrop</h3>
  </div>

  <div class="col-md-8">
    <p>
      Distribute your token to your community using this tool, by gathering user's wallet address and amount of token to
      distribute. <br />(Fee for distribution : 1 KUB)
    </p>
    <hr />
  </div>

  <div *ngIf="state === STATE.PENDING" class="col-md-12">
    <div class="mb-2">
      <label class="font-medium-1" for="token-address">Token Address</label>
      <p class="text-muted font-small-4">
        Insert your airdrop token address here, and ensure you have enough token in your wallet before start.
      </p>
      <input
        (change)="checkIsToken()"
        [(ngModel)]="tokenAddress"
        class="form-control input-mirror"
        id="token-address"
        placeholder="Enter Token Address"
        type="text" />
    </div>
    <div class="mb-2">
      <label class="font-medium-1" for="content"> Address, Amount</label>
      <p class="text-muted font-small-4">
        Insert your airdrop list here, in receiver wallet address and amount of token you want to send the airdrop to,
        separate by comma symbol. (here is the example, you can replace yours)
      </p>
      <ngx-codemirror
        (focusChange)="onContentChange($event)"
        [(ngModel)]="content"
        [options]="{
          lineNumbers: true,
          theme: 'yonce',
          mode: 'markdown'
        }"
        id="content"></ngx-codemirror>
      <div *ngIf="errors" [innerHTML]="errors" class="error-card"></div>
    </div>
    <div class="pull-right">
      <button
        (click)="processData()"
        *ngIf="!isLoading"
        [disabled]="!isToken || this.errors"
        class="btn btn-success"
        rippleEffect
        type="button">
        Next
      </button>
      <div *ngIf="isLoading" class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div *ngIf="state === STATE.PROCESSED || state === STATE.DONE" class="col-md-12">
    <ngb-alert [dismissible]="false" [type]="'info'">
      <div class="alert-body">
        You are about to transfer token with address <strong>{{ tokenAddress }}</strong> to these wallets,
        {{ total }} in total, please re-check again before process.
      </div>
    </ngb-alert>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Wallet Address</th>
          <th>Amount of token</th>
        </tr>
      </thead>
      <tr *ngFor="let distribution of distributions">
        <td>{{ distribution.walletAddress }}</td>
        <td>{{ distribution.tokenAmount }}</td>
      </tr>
    </table>
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="state === STATE.PROCESSED" class="pull-right">
          <div *ngIf="isLoading" class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <!-- <button *ngIf="!isLoading" class="btn btn-outline-success">Reset</button> -->
          <button (click)="approve()" *ngIf="shouldApprove && !isLoading" class="btn btn-success ml-2">Approve</button>
          <button (click)="airdrop()" *ngIf="!shouldApprove && !isLoading" class="btn btn-gradient ml-2">
            Airdrop
          </button>
        </div>

        <div *ngIf="state === STATE.DONE">
          <ngb-alert [dismissible]="false" [type]="'success'">
            <div class="alert-body">
              Success airdrop transaction hash :
              <a class="hash" href="https://www.bkcscan.com/tx/{{ txHash }}" target="_blank">{{ txHash }}</a>
            </div>
          </ngb-alert>
        </div>
      </div>
    </div>
  </div>
</div>
