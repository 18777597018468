<div class="row">
  <div class="col-md-12">
    <h3 class="d-inline-block text-gradient">About KUB Tools</h3>
  </div>

  <div class="col-md-8">
    <p>
      Hello there. Before this, I had to manually calculate things on the Bitkub chain, such as block number estimation,
      so I created and gathered these valuable tools for anyone on the Bitkub chain. If you want to support me, join our
      community, or buy me a coffee
      <img src="/assets/images/icons/coffee.png" style="width: 1.25rem; display: inline-block" />
      by transfer KUB or KUSDT to 0x354c66affdF129a6e0f53F16f1DF736c79da9fFF
      <a
        (click)="generalService.addToClipboard('0x354c66affdF129a6e0f53F16f1DF736c79da9fFF')"
        class="btn p-0"
        style="margin-top: -4px; margin-left: 5px">
        <i class="font-small-4 text-default" data-feather="copy"></i>
      </a>
    </p>
    <hr />
    <p>
      Join our
      <a href="https://discord.gg/ccxc5fkPAK">Discord</a>
      community, feel free to ask questions or request any features. or contact me at kub.tools@gmail.com
    </p>
  </div>

  <div class="col-md-4">
    <img src="./assets/images/3d/1.png" width="100%" />
  </div>
</div>
