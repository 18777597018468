<!--<div class="row">
    <div class="col-12 text-center">
        <img src="assets/images/3d/2.png" width="300px"/>
    </div>
    <div class="col-12 text-center">
        <h1 class="mt-3 text-gradient d-inline-block">Coming Soon</h1>
        <p>Stay tuned, under development process.</p>
    </div>
</div>
-->

<div class="row">

    <div class="col-md-12">
        <h3 class="d-inline-block text-gradient">Block Countdown</h3>
    </div>

    <div class="col-md-8">
        <p>
            Insert your block number to start countdown.

        </p>
        <hr/>

        <div class="row">
            <div class="col-12 mb-1">
                <div class="form-group">
                    <label for="block">Block number</label>
                    <input type="text" class="form-control input-dark" id="block" [(ngModel)]="block"  placeholder="Block Number" />
                </div>
            </div>
            <div class="col-12 mb-1">
                <div class="form-group">
                    <a (click)="calculate()" class="calculate-btn btn button-gradient button-two" >Countdown</a>
                </div>
            </div>

            <div class="col-12   text-center text-gradient font-large-2 font-weight-bold" *ngIf="hasResult">
                <hr/>
                <div class="mt-2 mb-1">
                <app-countdown [dDay]="targetDate" [isShowFinishText]="true" [finishTextDisplay]="'You are here !'"></app-countdown>
                </div>
            </div>

            <div class="info col-12" >
                <hr/>
                <div class="row">
                    <div class="col-6 mb-1" *ngIf="block">
                        Share
                    </div>
                    <div class="col-6  text-right" *ngIf="block">
                        <div  >
                            <a class="  font-weight-bold text-gradient " (click)="generalService.addToClipboard((baseUrl+'/block-countdown?block='+block))">Copy link</a>
                        </div>
                    </div>
                    <div class="col-6 mb-1">
                        Countdown for block
                    </div>
                    <div class="col-6 text-right">
                        {{block ? (block | number:'1.0-0') : '-'}}
                    </div>

                    <div class="col-6 mb-1">
                        Target Date
                    </div>
                    <div class="col-6 text-right">
                        {{targetDate ? (targetDate | date:'medium') : '-'}}
                    </div>

                    <div class="col-6 mb-1">
                        Current Block
                    </div>
                    <div class="col-6 text-right">
                        {{currentBlock ? currentBlock:'-'}}
                    </div>
                    <div class="col-6 mb-1">
                        Current Date Time
                    </div>
                    <div class="col-6 text-right">
                        {{currentDate ? (currentDate | date:'medium') : '-'}}
                    </div>

                    <div class="col-6 mb-1">
                        Remain blocks
                    </div>
                    <div class="col-6 text-right">
                        {{remainBlock ? remainBlock:'-'}}
                    </div>


                </div>
            </div>



        </div>

    </div>

    <div class="col-md-4">
        <img src="./assets/images/3d/4.png" width="100%"  >
    </div>


</div>
