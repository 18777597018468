import { Component, OnInit } from '@angular/core';
import {ethers} from "ethers";
import {GeneralService} from "../../services/general.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-block-calculator',
  templateUrl: './block-calculator.component.html',
  styleUrls: ['./block-calculator.component.scss']
})
export class BlockCalculatorComponent implements OnInit {


  date:any;
  block = 0;
  currentBlock = 0;
  currentDate = new Date()
  publicProvider
  secondDiff = 0
  blockTimestamp = new Date()
  blockUnixTimestamp = 0

  constructor(public generalService:GeneralService, private toastr: ToastrService) { }

  async ngOnInit(){
    //this.date =  (new Date()).toISOString().slice(0, -5)
    this.publicProvider = await new ethers.providers.JsonRpcProvider("https://rpc.bitkubchain.io")
    this.currentBlock = await this.publicProvider.getBlockNumber();
    this.currentDate = new Date()
    this.blockUnixTimestamp = (await this.publicProvider.getBlock(this.currentBlock)).timestamp
    this.blockTimestamp = new Date(this.blockUnixTimestamp * 1000);
  }

  async calculate(){
    this.secondDiff = new Date(this.date).getTime()/1000 - this.blockUnixTimestamp //this.currentDate.getTime()/1000
    //console.log(this.secondDiff)
    //console.log(Math.floor(this.secondDiff/5))
    this.block = this.currentBlock + Math.floor(this.secondDiff/5)

  }

  toIsoString(_date) {
    var tzo = -_date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
          return (num < 10 ? '0' : '') + num;
        };

    return _date.getFullYear() +
        '-' + pad(_date.getMonth() + 1) +
        '-' + pad(_date.getDate()) +
        'T' + pad(_date.getHours()) +
        ':' + pad(_date.getMinutes()) +
        ':' + pad(_date.getSeconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
  }


}
